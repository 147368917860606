import { NgModuleRef } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';
import { Environment } from './model';

export const ENV_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBxX7UWPRmzAjBc1iczUPY9RUUhiaTTwj8',
  authDomain: 'freeup-mobile.firebaseapp.com',
  databaseURL: 'https://freeup-mobile.firebaseio.com',
  projectId: 'freeup-mobile',
  storageBucket: 'freeup-mobile.appspot.com',
  messagingSenderId: '787801171522'
};
export const Title = 'Premium Cell Phone Plans with 4G LTE Nationwide Service | FreeUp Mobile' ;
export const Description = 'FreeUP runs on the largest and fastest 4G LTE network with no speed restrictions. Pick a plan and get Free SIM card & shipping.';
export const ENDPOINT_URL = 'https://freeup-zwp-stage.ztarmobile.io';
export const ENV_ENDPOINT_URL = '';
export const BFF_ENDPOINT_URL = 'https://freeup-zwp-stage.ztarmobile.io' ;
export const CUSTOMER_CARE_NUMBER = '8883160686';
export const CAPTCHA_SITE_ID = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const INVISIBLE_CAPTCHA_ID = '6LebMcMpAAAAAH0JWFGp1ubZC8R_rEGuVoMVL6pz';
export const BUILD_VERSION = require('../../package.json').version ;
export const BUILD_DATE = JSON.stringify((new Date()).toISOString());
export const GEOCODE_API_KEY = 'AIzaSyBLEp1LJrBqnCibrCdjkptaOB66y68B-aU';
export const GTM_API_KEY = 'GTM-5BHGWDH';
export const GOOGLE_ADSENSE_CLIENT = 'ca-pub-6676283552039995';
export const PASSWORD_PATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,12}$';
export const WEBSITE_URL = 'https://freeup-stage.ztarmobile.io';
export const ZMP_FREEUP_BFF_ENDPOINT_URL = 'https://freeup-zmp-stage.ztarmobile.io';
export const GOOGLE_CLIENT_ID = '137465270934-7gipmpdjkmdlb22m665n151897su6ln9.apps.googleusercontent.com';
export const CONTENTFUL = {
  spaceId: 'g0gl1myl8rc8',
  token: 'hhgKkIjVunoqBufGbf67RsD7SCvWdGARCiOl-Srk764',
  environment: 'production'
};
export const PHONE_SPACE = {
  spaceId: 'oufplo9dsind',
  token: 'di6HPlO0KiS3-WuOWKcIaIe1rNTSpZ9Ut5pytiaMX10',
  environment: 'production'
};
export const environment: Environment = {
  production: false,
  showDevModule: true,
  // eslint-disable-next-line
  decorateModuleRef(modRef: NgModuleRef<any>) {
    disableDebugTools();
    return modRef;
  },
  ENV_PROVIDERS: [

  ]
};
